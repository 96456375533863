import NextLink from 'next/link'
import styled from 'styled-components'

import { theme } from 'styles/theme'

interface WrapperProps {
  readonly background: string
  readonly border: string
}

const { colors, radii, space, fontWeights, transitions } = theme

const setColor = color => {
  if (color.match(/^[A-F\d]{6}$|^[A-F\d]{3}$/i) !== null) {
    return `#${color}`
  } else {
    return colors[color]
  }
}

export const Wrapper = styled.div<WrapperProps>`
  border: 1px solid ${props => setColor(props.border)};
  background: ${props => setColor(props.background)};
  border-radius: ${radii['corner-radius-md']};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Link = styled(NextLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${space['spacing-md']};
  background-color: ${colors['primary-background-hover']};
  color: ${colors['primary-text-default']};
  font-weight: ${fontWeights['font-weight-medium']};
  border-bottom-left-radius: ${space['spacing-sm']};
  border-bottom-right-radius: ${space['spacing-sm']};
  transition: ${transitions.default};

  &:hover {
    background-color: ${colors['primary-background-pressed']};
  }
`
