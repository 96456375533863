import { FC, memo } from 'react'

import { Button, Text } from '@cash-me/react-components'

import { Card } from 'components/card'
import { Infos } from 'components/general-styles'
import { Grid, Col } from 'components/grid'
import useWindowSize from 'hooks/useWindowSize'

import { BlogLinksProps } from './blog-links.model'
import * as S from './styles'

const BlogLinks: FC<BlogLinksProps> = memo(({ onClick }) => {
  const { isMobile } = useWindowSize()

  return (
    <S.Section bg="medium">
      <Grid col={{ phone: 1, tablet: 1 }}>
        <Col col={{ desktop: 10 }} start={{ desktop: 2 }}>
          <Infos>
            <Text as="h2" size="heading3" weight="medium">
              Saiba tudo sobre o consórcio no
              {' '}
              <Text as="span" size="heading3" weight="medium" color="var(--colors-pink-text-default)">
                blog da CashMe
              </Text>
            </Text>
            <S.CardsWrapper>
              <Card
                link="https://www.cashme.com.br/blog/como-ser-contemplado-no-consorcio/"
                dataLayer={{
                  category: 'Clique',
                  action: 'Consórcio',
                  label: 'Blog Links - Como ser contemplado no consórcio?'
                }}
              >
                <S.CardContent>
                  <Text color="var(--colors-neutral-text-default)" size="heading4" weight="medium">
                    Como ser contemplado no consórcio?
                  </Text>
                </S.CardContent>
              </Card>
              <Card
                link="https://www.cashme.com.br/blog/lance-embutido-no-consorcio-o-que-e-e-como-fazer/"
                dataLayer={{
                  category: 'Clique',
                  action: 'Consórcio',
                  label: 'Blog Links - O que é lance embutido no consórcio?'
                }}
              >
                <S.CardContent>
                  <Text color="var(--colors-neutral-text-default)" size="heading4" weight="medium">
                    O que é lance embutido no consórcio?
                  </Text>
                </S.CardContent>
              </Card>
              <Card
                link="https://www.cashme.com.br/blog/como-dar-lance-no-consorcio/"
                dataLayer={{
                  category: 'Clique',
                  action: 'Consórcio',
                  label: 'Blog Links - Como dar lance no consórcio?'
                }}
              >
                <S.CardContent>
                  <Text color="var(--colors-neutral-text-default)" size="heading4" weight="medium">
                    Como dar lance no consórcio?
                  </Text>
                </S.CardContent>
              </Card>
              <Card
                link="https://www.cashme.com.br/blog/carta-de-credito/"
                dataLayer={{
                  category: 'Clique',
                  action: 'Consórcio',
                  label: 'Blog Links - O que é carta de crédito?'
                }}
              >
                <S.CardContent>
                  <Text color="var(--colors-neutral-text-default)" size="heading4" weight="medium">
                    O que é carta de crédito?
                  </Text>
                </S.CardContent>
              </Card>
            </S.CardsWrapper>
          </Infos>
          {isMobile && 
            <Button size="lg" onClick={onClick} iconName="arrowRight">
              Saiba mais
            </Button>
          }
        </Col>
      </Grid>
    </S.Section>
  )
})

BlogLinks.displayName = 'BlogLinks'

export default BlogLinks
