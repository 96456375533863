import styled from 'styled-components'

import { Section as SectionDS } from 'components/general-styles'
import { theme } from 'styles/theme'

const { media, colors, space, sizes, radii } = theme

export const Section = styled(SectionDS)`
  .ds-button {
    display: block;
    width: 100%;
    margin-top: ${space['spacing-md']};

    span {
      display: none;
    }
  }
`

export const CardsWrapper = styled.div`
  display: flex;
  gap: ${space['spacing-sm']};
  overflow-x: auto;

  @media (${media.tablet}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: ${space['spacing-md']};
  }
`

export const CardContent = styled.div`
  min-width: 200px;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  gap: ${space['spacing-sm']};
  padding: ${space['spacing-sm']};
  
  span {
    color: ${colors['blue-icon-default']};
    background: ${colors['primary-background-hover']};
    border-radius: ${radii['corner-radius-circular']};
    width: ${sizes['spacing-xl']};
    height: ${sizes['spacing-xl']};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: ${sizes['spacing-md']};
      height: ${sizes['spacing-md']};
    }
  }
`