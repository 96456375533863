import { FC, memo } from 'react'

import { Icon } from '@cash-me/react-components'

import { pushDataLayer } from 'utils/push-data-layer'

import { CardProps } from './card.model'
import * as S from './styles'

export const Card: FC<CardProps> = memo(
  ({ children, background = 'neutral-background-default', border = 'neutral-border-default', link, dataLayer }) => (
    <S.Wrapper background={background} border={border}>
      {children}
      {link && (
        <S.Link {...(dataLayer && { onClick: () => pushDataLayer(dataLayer) })} href={link}>
          Saiba mais
          <Icon name="arrowRight" color="var(--colors-primary-icon-default)" size={24} />
        </S.Link>
      )}
    </S.Wrapper>
  )
)

Card.displayName = 'Card'
